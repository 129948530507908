import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSortBy, useTable } from "react-table";
import { Card, Container, Nav, Navbar, Table } from "reactstrap";
import PaginationContainer from "../../components/PaginationContainer";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import actions from "../../redux/actions";
import CreateVital from "./CreateVital";

export default function Vitals() {
  const [tableData, setTableData] = React.useState([]);
  const [isCreateModalVisible, setIsCreateModal] = React.useState(false);
  const [vitalParamData, setVitalParamData] = React.useState([]);
  const [editData, setEditData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const getApiCall = () => {
    actions
      .getVitalData({
        pageNo: currentPage,
      })
      .then((res) => {
        setPageCount(Math.ceil(res.data?.count / 10));
        console.log(res, "the result");
        console.log(res.data, "the data value");
        const data =
          (res.data.listRecord &&
            res.data.listRecord.map((val) => {
              console.log(val["vitalParameterIdVRL.name"], "the valuasdfa");
              return {
                ...val,
                vitalParameter:
                  val["vitalParameterIdVRL.name"] ||
                  val?.vitalParameterIdVRL?.name ||
                  "",
              };
            })) ||
          [];
        setTableData(data);
      })
      .catch((err) => {
        console.log(err, "the error");
      });
  };
  React.useEffect(() => {
    getApiCall();
  }, [currentPage]);
  React.useEffect(() => {
    actions
      .getVitalParam({
        pageNo: 1,
      })
      .then((res) => {
        console.log(res, "the result value");
        const vitalData = res.data?.listRecord.map((val) => ({
          value: val.id,
          label: val?.name || "",
        }));
        console.log(vitalData, "the vital data value is as follow");
        setVitalParamData(vitalData);
      });
  }, []);
  const data = React.useMemo(() => tableData, [tableData]);
  console.log(tableData, "the aoijsfoidjoj");
  const columns = React.useMemo(
    () => [
      {
        Header: "Start Range",
        accessor: "vitalStartRange",
      },
      {
        Header: "End Range",
        accessor: "vitalEndRange",
      },
      {
        Header: "Vital Parameter",
        accessor: "vitalParameter", // accessor is the "key" in the data
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
    ],
    []
  );

  const onPageClick = (event) => {
    console.log(event.selected, "the evjoaijsdofjo");
    setCurrentPage(event.selected + 1);

    const newOffset = (event.selected * 10) % 20;
    console.log(newOffset, "thejojaosjdoj ");
  };

  const openModal = () => {
    setEditData({});
    setisEdit(false);
    setIsCreateModal(true);
  };

  const closeModal = () => {
    setisEdit(false);
    setIsCreateModal(false);
  };

  const _onAddEditSuccess = () => {
    setIsCreateModal(false);
    getApiCall();
  };

  const _onEdit = (data) => {
    setIsCreateModal(true);
    setisEdit(true);
    setEditData(data);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>

        <div
          className="create-btn"
          style={{ textDecoration: "none" }}
          onClick={openModal}
        >
          Add Vital Range
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td className="texta">{(currentPage - 1) * 10 + i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      type={"VITAL_RANGE"}
                      onEdit={_onEdit}
                      data={row.original || {}}
                      onDeleteComplete={getApiCall}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={onPageClick} pageCount={pageCount} />
      </Card>
      {isCreateModalVisible && (
        <CreateVital
          vitalParamData={vitalParamData}
          modalVisible={isCreateModalVisible}
          toggleModal={closeModal}
          onAddEditSuccess={_onAddEditSuccess}
          isEdit={isEdit}
          editData={editData}
        />
      )}
    </Container>
  );
}
