//File Name: Login.js
//Path: src/utilities/validations/
//Description: This file contains validation functions for student login form.
import Validator from "is_js";
import { isEmpty } from "lodash";

export default function validateInput(data) {
  let errors = {};

 
  if (Validator.empty(data.gender)) {
    errors.gender = "Gender is required";
  }


  if (Validator.empty(data.vitalStartRange)) {
    errors.vitalStartRange = "Start Range is required";
  }

  if (Validator.empty(data.vitalEndRange)) {
    errors.vitalEndRange = "End Range is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
