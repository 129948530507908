import { loginAPI } from "../../api/auth";
import types from "../types";
import store from "../store";
import { addEditSymptomAPI,getSymptomDataAPI,deleteSymptomAPI } from "../../api/symptoms";


const { dispatch } = store;

export function addEditSymptom(payload) {
  // dispatch({
  // 	type: types.AUTH_LOGIN
  // })

  return addEditSymptomAPI(payload);
}


export function getSymptoms(data={}){
  return getSymptomDataAPI(data)
}

export function deleteSymptom(payload) {
  return deleteSymptomAPI(payload);
}