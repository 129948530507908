import React, { useState } from "react";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import { Card, Container, Nav, Navbar, Table } from "reactstrap";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";
import CreateEditDiseaseCategory from "./CreateEditDiseaseCategory";

export default function DiseaseCategory(props) {
  const location = useLocation();
  const [tableData, setTableData] = React.useState([]);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [state, setState] = React.useState({
    optionString: "",
    value: "",
    sequenceNo: "",
  });
  const [editData, setEditData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);

  React.useEffect(() => {
    getApiCall();
  }, []);

  const getApiCall = () => {
    actions
      .listDiseaseCategory({
        pageNo: 1,
      })
      .then((res) => {
        setTableData(res.data?.rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDeleteConfirm = (data,isBlockAction) => {
    let status="DELETE";
    if(isBlockAction){
      status=data.status=="BLOCK"?"UNBLOCK":"BLOCK"
    }
    actions
      .deleteDiseaseCategory({
        status,
        id: data.id,
      })
      .then((res) => {
        getApiCall();
      })
      .catch((error) => {
        showError(error);
      });
  };
  const data = React.useMemo(() => tableData, [tableData]);
  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Code",
        accessor: "code",
      },
    ],
    []
  );

  const toggleModal = () => {
    setisEdit(false);
    setIsModalVisible(false);
  };

  const openOptionModal = () => {
    setisEdit(false);
    setIsModalVisible(true);
    setEditData({});
  };

  const onOptionSubmit = () => {
    const { isValid, errors } = validateInput({
      optionString: { value: state.optionString, name: "Question" },
      sequenceNo: { value: state.sequenceNo, name: "Sequence no" },
      value: { value: state.value, name: "Score Deduction" },
    });

    console.log(errors, "the errors data value");
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      console.log(location, "the locaitojo");
      const { data } = location?.state;
      const questionId = data.id;
      actions
        .addEditOptionForQueston({
          ...state,
          questionId: questionId + "",
        })
        .then((res) => {
          getApiCall();
          console.log(res, "the resule vaue");
          setIsLoading(false);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.log(error, "the error value");
          setIsLoading(false);
          showError(error);
        });
    }
  };

  const onAddEditomplete = () => {
    setIsModalVisible(false);
    getApiCall();
  };

  const _onEdit = (data) => {
    let editData = {
      name: data.name,
      code: data.code,
      id: data.id,
    };
    setEditData(editData);
    setIsModalVisible(true);
    setisEdit(true);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>

        <div
          className="create-btn"
          style={{ textDecoration: "none" }}
          onClick={openOptionModal}
        >
          Add Disease Category
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td className="texta">{i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      onEdit={_onEdit}
                      data={row.original || {}}
                      onDeleteConfirm={onDeleteConfirm}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      {modalVisible && (
        <CreateEditDiseaseCategory
          modalVisible={modalVisible}
          onAddEditomplete={onAddEditomplete}
          toggleModal={toggleModal}
          isEdit={isEdit}
          editData={editData}
        />
      )}
    </Container>
  );
}
