import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
export default function Overview() {
  return (
    <Container>
        <div className="justify-content-between">
            <h4>Welcome to moringa</h4>
        </div>
      {/* <Row className="justify-content-between">
        <Col xs="3">
          <CardComp />
        </Col>
        <Col xs="3">
          <CardComp />
        </Col>
        <Col xs="3">.col</Col>
      </Row> */}
    </Container>
  );
}

const CardComp = () => {
  return (
    <Card>
      <CardImg
        top
        width="100%"
        src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180"
        alt="Card image cap"
      />
      <CardBody>
        <CardTitle>Card title</CardTitle>
        <CardSubtitle>Card subtitle</CardSubtitle>
        <CardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </CardText>
        <Button>Button</Button>
      </CardBody>
    </Card>
  );
};
