import React, { useState } from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";
import Select from "react-select";

export default function EditQuestion({
  modalVisible,
  toggleModal,
  onAddEditomplete,
  vitalParamData,
  editData,
  isEdit=false
}) {
  const [state, setState] = React.useState({
    
    questionText:editData?.questionText|| "",
    type:editData.type|| "",
    noOfInputsArray: [1],
    ...editData,
  });

  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((val) => ({ ...val, ...data }));
  const [selectedOption, setSelectedOption] = useState(null);

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onParamSelect = (data) => {
    console.log(data, "the data value");
    setSelectedOption(data);
  };
  const onOptionSubmit = () => {
    let { isValid, errors } = validateInput({
      questionText: { value: state.questionText, name: "Question Text" },
      [`optionPlaceHolder1`]: {
        value: state[`optionPlaceHolder1`],
        name: "Option Placeholder 1",
      },
      ...(state.noOfInputsArray.length > 1 && {
        [`optionPlaceHolder2`]: {
          value: state[`optionPlaceHolder2`],
          name: "Option Placeholder 2",
        },
      }),
    });
    if (!selectedOption) {
      isValid = false;
      errors.selectedOption = "Select a parameter";
    }
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      const { noOfInputsArray } = state;
      const totalInputs = noOfInputsArray.length;
      const apiData = {
        questionText: state.questionText,
        vitalParameterId: selectedOption.value,
        type: totalInputs,
        optionPlaceHolder: state.optionPlaceHolder1,
      };
      if (totalInputs > 1) {
        apiData.optionPlaceHolder = `${apiData.optionPlaceHolder},${state.optionPlaceHolder2}`;
      }

      if(isEdit){
        apiData.id=state.id;
      }

      console.log(apiData, "the api data sent");

      actions
        .addEditVitalQuestion({
          ...apiData,
        })
        .then((res) => {
          console.log(res, "the result value");
          onAddEditomplete();
        })
        .catch((error) => {
          console.log(error, "the error value");
          //   setIsLoading(false);
          showError(error);
        });
    }
  };

  console.log(errorObj, "the errA");
  return (
    <Modal centered isOpen={modalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{isEdit?"Edit Vital Question":"Add Vital Question"}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Question Text</label>
          <Input
            name={"questionText"}
            onChange={onChangeText}
            value={state.questionText}
            invalid={!!errorObj.questionText}
            type="text"
          />
          <FormFeedback>{errorObj.questionText}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>No of TextInput</label>
          <div>
            <div className="form-check form-check-inline">
              <Input
                className="form-check-input radio-inline"
                type="radio"
                name="textInput"
                id="flexRadioDefault1"
                checked={state.noOfInputsArray.length == 1}
                onClick={() => updateState({ noOfInputsArray: [1] })}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                1
              </label>
            </div>
            <div className="form-check form-check-inline">
              <Input
                className="form-check-input radio-inline"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked={state.noOfInputsArray.length == 2}
                onClick={() => updateState({ noOfInputsArray: [1, 2] })}
              />
              <label className="form-check-label" for="flexRadioDefault2">
                2
              </label>
            </div>
          </div>
        </FormGroup>

        {state.noOfInputsArray.map((val, i) => {
          let dataKeyName = `optionPlaceHolder${i + 1}`;
          return (
            <FormGroup key={String(i)}>
              <label>Option Placeholder {String(i + 1)}</label>
              <Input
                name={dataKeyName}
                onChange={onChangeText}
                value={state[dataKeyName]}
                invalid={!!errorObj[dataKeyName]}
                type="text"
              />
              <FormFeedback>{errorObj[dataKeyName]}</FormFeedback>
            </FormGroup>
          );
        })}

        <FormGroup>
          <label>Vital Parameter</label>
          <Select
            value={selectedOption}
            onChange={onParamSelect}
            options={vitalParamData}
          />
          <p
            className={`invalid-feedback ${
              !!errorObj.selectedOption ? "error-text" : ""
            }`}
            valid={false}
          >
            {errorObj.selectedOption}
          </p>
        </FormGroup>

        <div
          className="create-btn"
          style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          onClick={onOptionSubmit}
        >
          Submit
        </div>
      </ModalBody>
    </Modal>
  );
}
