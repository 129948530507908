import React, { useState } from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";
export default function CreateEditDiseaseCategory({
  modalVisible,
  toggleModal,
  onAddEditomplete,
  isEdit,
  editData = {},
}) {
  const [state, setState] = React.useState({
    name: "",
    code: "",
    ...editData,
  });
  console.log(editData, "th ei");
  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((val) => ({ ...val, ...data }));

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onOptionSubmit = () => {
    const { isValid, errors } = validateInput({
      name: { value: state.name, name: "Name" },
      code: { value: state.code, name: "Code" },
    });

    console.log(errors, "the errors data value");
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      actions
        .addEditDiseaseCategory({
          ...state,
        })
        .then((res) => {
          console.log(res, "the response value");
          onAddEditomplete();
        })
        .catch((error) => {
          console.log(error, "the error value");
          //   setIsLoading(false);
          showError(error);
        });
    }
  };

  console.log(errorObj, "the errA");
  console.log(state.code + "asd");
  return (
    <Modal centered isOpen={modalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{`${
        isEdit ? "Edit" : "Add"
      } Disease Category`}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Name</label>
          <Input
            name={"name"}
            onChange={onChangeText}
            value={state.name}
            invalid={!!errorObj.name}
            type="text"
          />
          <FormFeedback>{errorObj.name}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>Category Code</label>
          <Input
            name={"code"}
            onChange={onChangeText}
            value={state.code}
            invalid={!!errorObj.code}
            // type="number"
          />
          <FormFeedback>{errorObj.code}</FormFeedback>
        </FormGroup>
       

        <div
          className="create-btn"
          style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          onClick={onOptionSubmit}
        >
          Submit
        </div>
      </ModalBody>
    </Modal>
  );
}
