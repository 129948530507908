import React, { useState } from "react";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import { Card, Container, Nav, Navbar, Table } from "reactstrap";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";

import CreateEditQuestion from "./CreateEditQuestion";
import PaginationContainer from "../../components/PaginationContainer";
export default function AddEditQuestion(props) {
  const location = useLocation();
  const [tableData, setTableData] = React.useState([]);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [state, setState] = React.useState({
    optionString: "",
    value: "",
    sequenceNo: "",
  });
  const [vitalParamData, setVitalParamData] = React.useState([]);
  const [editQuestionData, setEditQuestionData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  React.useEffect(() => {
    getApiCall();
  }, [currentPage]);

  React.useEffect(() => {
    actions
      .getVitalParam({
        pageNo: 1,
      })
      .then((res) => {
        
        console.log(res, "the result value");
        const vitalData = res.data?.listRecord.map((val) => ({
          value: val.id,
          label: val?.name || "",
        }));
        setVitalParamData(vitalData);
      });
  }, []);

  const getApiCall = () => {
    actions
      .getVitalQuestionApi({
        pageNo: currentPage,
      })
      .then((res) => {
        console.log(res, "the result value is as follow");
        setPageCount(Math.ceil(res.data?.count / 10));
        const data =
          (res.data.listRecord &&
            res.data.listRecord.map((val) => ({
              ...val,
              vitalParameter: val.vitalParameterIdVQS?.name,
            }))) ||
          [];
        setTableData(data);
      })
      .catch((error) => {
        setIsLoading(false);
        showError(error);
      });
  };

  const onPageClick = (event) => {
    console.log(event.selected, "the evjoaijsdofjo");
    setCurrentPage(event.selected + 1);

    const newOffset = (event.selected * 10) % 20;
    console.log(newOffset, "thejojaosjdoj ");
  };

  const data = React.useMemo(() => tableData, [tableData]);
  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const columns = React.useMemo(
    () => [
      {
        Header: "Question",
        accessor: "questionText", // accessor is the "key" in the data
      },
      {
        Header: "No of Inputbox",
        accessor: "type",
      },
      {
        Header: "Option Placeholder",
        accessor: "optionPlaceHolder",
      },
      {
        Header: "Vital Parameter",
        accessor: "vitalParameter",
      },
    ],
    []
  );

  const toggleModal = () => {
    setisEdit(false);
    setIsModalVisible(false);
  };

  const openOptionModal = () => {
    setisEdit(false);
    setIsModalVisible(true);
    setEditQuestionData({})
  };

  const onOptionSubmit = () => {
    const { isValid, errors } = validateInput({
      optionString: { value: state.optionString, name: "Question" },
      sequenceNo: { value: state.sequenceNo, name: "Sequence no" },
      value: { value: state.value, name: "Score Deduction" },
    });

    console.log(errors, "the errors data value");
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      console.log(location, "the locaitojo");
      const { data } = location?.state;
      const questionId = data.id;
      actions
        .addEditOptionForQueston({
          ...state,
          questionId: questionId + "",
        })
        .then((res) => {
          getApiCall();
          console.log(res, "the resule vaue");
          setIsLoading(false);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.log(error, "the error value");
          setIsLoading(false);
          showError(error);
        });
    }
  };

  const onAddEditomplete = () => {
    getApiCall();
    setIsModalVisible(false);
  };

  const _onEdit = (data) => {
    setisEdit(true);
    const optionsPlaceholderArray = data?.optionPlaceHolder?.split(",");
    console.log(optionsPlaceholderArray,'the options placeholder arrray')
    let edit = {
      questionText: data?.questionText || "",
      type: data.type || "",
      noOfInputsArray: optionsPlaceholderArray.length == 1 ? [1] : [1, 2],
      [`optionPlaceHolder1`]: optionsPlaceholderArray[0],
      id:data.id
    };
    console.log({...edit})
    if (optionsPlaceholderArray[1]) {
      edit[`optionPlaceHolder2`] = optionsPlaceholderArray[1];
    }

    setEditQuestionData(edit);
    setIsModalVisible(true);
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>

        <div
          className="create-btn"
          style={{ textDecoration: "none" }}
          onClick={openOptionModal}
        >
          Add Vital Question
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td className="texta">{i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      onEdit={_onEdit}
                      type={'VITAL_QUESTIONS'}
                      onDeleteComplete={getApiCall}
                      data={row.original || {}}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={onPageClick} pageCount={pageCount} />
      </Card>
      {modalVisible && (
        <CreateEditQuestion
          modalVisible={modalVisible}
          onAddEditomplete={onAddEditomplete}
          toggleModal={toggleModal}
          vitalParamData={vitalParamData}
          isEdit={isEdit}
          editData={editQuestionData||{}}
        />
      )}
    </Container>
  );
}
