import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { saveUserData } from "./redux/reducers/auth";
import { getUser } from "./utils";

import AppRouter from "./AppRouter";

const App = () => {
  const userData = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const user = getUser();
    if (!!user) {
      dispatch(saveUserData(user));
    }
  }, []);

  console.log(userData, "the user data value is as follow");
  const isLogin = !!userData.accessToken;

  return <AppRouter />;
};

export default App;
