import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./components/ProtectedRoutes";
import PublicRoutes from "./components/PublicRoutes";
import DashboardContainer from "./layouts/Dashboard/DasboardContainer";
import DashboardRoutes from "./layouts/Dashboard/DashboardRoutes";
import Login from "./views/Login/Login";

export default function RootRoutes() {
  const userData = useSelector((state) => state.auth.userData);
  const isLogin = !!userData.accessToken;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<DashboardContainer />}>
            {DashboardRoutes()}
          </Route>
        </Route>
        <Route path="/" element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
