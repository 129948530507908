import React, { useState } from "react";
import { Navigate, useHistory, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  FormFeedback,
  Modal,
  ModalHeader,
} from "reactstrap";
import actions from "../../redux/actions";
import validateInput from "../../utilities/validations/vitals";
import { useLocation } from "react-router-dom";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { showError } from "../../utilities/alerts/toastAlerts";
import Select from "react-select";
const GENDERS = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  CHILDREN: "CHILDREN",
};
export default function CreateVital(props) {
  console.log(props, "the props value");
  let location = useLocation();
  const navigate = useNavigate();
  const {
    modalVisible,
    toggleModal,
    vitalParamData,
    onAddEditSuccess,
    isEdit,
    editData={},
  } = props;

  console.log(location, "the creawte vital locaoijoj");

  const [state, setState] = React.useState({
    gender:editData.gender|| "",
    vitalStartRange: (editData.vitalStartRange || "") + "",
    vitalEndRange: (editData.vitalEndRange || "") + "",
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [errorObj, setErrorData] = useState({
    gender: "",
    vitalStartRange: "",
    vitalEndRange: "",
  });

  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onCreateVital = () => {
    let { isValid, errors } = validateInput(state);
    console.log(errors, "the error value");
    if (!selectedOption) {
      isValid = false;
      errors.selectedOption = "Select a parameter";
    }
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      setIsLoading(true);
      let apiData = { ...state, vitalParameterId: selectedOption.value };
      if (isEdit) {
        apiData.id = editData.id;
      }
      actions
        .addEditVitals(apiData)
        .then((res) => {
          console.log("vital data sucess", res);
          onAddEditSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          showError(error);
        });
    }
  };

  const onParamSelect = (data) => {
    console.log(data, "the data value");
    setSelectedOption(data);
  };

  const onGenderSelect = (gender) => {
    if (errorObj.gender) {
      setErrorData((data) => ({ ...data, gender: "" }));
    }
    updateState({ gender });
  };

  console.log(isEdit, "the is edi");

  const { gender } = state;
  console.log(errorObj, "the erro");
  return (
    <Modal
      modalClassName="loader"
      centered
      isOpen={modalVisible}
      toggle={toggleModal}
    >
      <Card className="shadow p-3 pt-4  bg-white ">
        <ModalHeader toggle={toggleModal}>{"Add Vital Range"}</ModalHeader>
        <CardBody>
          <Form>
            <FormGroup>
              <label>Start Range</label>
              <Input
                name={"vitalStartRange"}
                onChange={onChangeText}
                value={state.vitalStartRange}
                invalid={!!errorObj.vitalStartRange}
                type="number"
              />
              <FormFeedback>{errorObj.vitalStartRange}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <label>End Range</label>
              <Input
                name={"vitalEndRange"}
                invalid={!!errorObj.vitalEndRange}
                onChange={onChangeText}
                value={state.vitalEndRange}
                type="number"
              />
              <FormFeedback>{errorObj.vitalEndRange}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <label>Gender</label>
              <div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input radio-inline"
                    type="radio"
                    name="textInput"
                    id="flexRadioDefault1"
                    defaultChecked
                    checked={gender === GENDERS.MALE}
                    onClick={() => onGenderSelect(GENDERS.MALE)}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input radio-inline"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={gender === GENDERS.FEMALE}
                    onClick={() => onGenderSelect(GENDERS.FEMALE)}
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Female
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input radio-inline"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={gender === GENDERS.CHILDREN}
                    onClick={() => onGenderSelect(GENDERS.CHILDREN)}
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Children
                  </label>
                </div>
              </div>
              <p
                className={`invalid-feedback ${
                  !!errorObj.gender ? "error-text" : ""
                }`}
                valid={false}
              >
                {errorObj.gender}
              </p>
            </FormGroup>
            <FormGroup>
              <label>Vital Parameter</label>
              <Select
                value={selectedOption}
                onChange={onParamSelect}
                options={vitalParamData}
              />
              <p
                className={`invalid-feedback ${
                  !!errorObj.selectedOption ? "error-text" : ""
                }`}
                valid={false}
              >
                {errorObj.selectedOption}
              </p>
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter className="cfoot">
          <div className="update ml-auto mr-auto">
            <Button
              onClick={onCreateVital}
              className="btn-round new-prime"
              color="primary"
              type="submit"
            >
              {isEdit ? "Update" : `Create`}
            </Button>
          </div>
        </CardFooter>
      </Card>

      <FullScreenLoader isLoading={isLoading} />
    </Modal>
  );
}
