export const LOGIN = `Admin/login`;
export const ADD_EDIT_VITAL = `Admin/add_edit_vital_data`;
export const LIST_VITAL_DATA = `Admin/list_vital_data`;
export const ADD_EDIT_SYMPTOMS = `Admin/add_edit_symptoms`;
export const LIST_SYMPTOMS = `Admin/list_symptoms`;
export const ADD_EDIT_PROFILE_QUESTIONS = `Admin/add_edit_profile_question`;
export const ADD_EDIT_OPTION_FOR_QUESTIONS = `Admin/add_edit_options_for_question`;
export const LIST_OPTION_FOR_QUESTIONS = `Admin/list_options_for_question`;
export const LIST_PROFILE_QUESTION_DATA = `Admin/list_profile_question_data`;
export const ADD_EDIT_VITAL_DATA = `Admin/add_edit_vital_data`;
export const ADD_EDIT_VITAL_PARAM = `Admin/add_edit_vital_parameters`;
export const ADD_VITAL_QUESTION = `Admin/add_edit_vital_question`;
export const LIST_VITAL_QUESTION = `Admin/list_vital_question`;
export const LIST_VITAL_PARAMETER = `Admin/list_vital_parameters`;
export const CREATE_EDIT_DISEASE_CATEGORY = `Admin/add_edit_disease_category`;
export const CREATE_EDIT_DISEASE = `Admin/add_edit_disease`;
export const LIST_DISEASE_CATEGORY = `Admin/list_disease_category`;
export const LIST_DISEASE = `Admin/list_disease`;
export const CREATE_EDIT_RELATION = `Admin/add_edit_vsd_match`;
export const LIST_RELATION = `Admin/list_vsd_match`;
export const DELETE_BLOCK_UNBLOCK = `Admin/update_status`;
export const DELETE_BLOCK_STATUS_DISEASE = `Admin/update_status_disease`;
export const DELETE_BLOCK_DISEASE_CATEGORY = `Admin/update_status_diseaseCategory`;
export const DELETE_BLOCK_SYMPTOMS = `Admin/update_status_symptoms`;
export const DELETE_BLOCK_VITAL_PARAM = `Admin/update_status_vital_parameters`;
export const DELETE_BLOCK_RELATION = `Admin/update_status_vsd`;



