import { loginAPI } from "../../api/auth";
import types from "../types";
import store from "../store";
import {
  addEditVitalAPI,
  addEditVitalParamAPI,
  addEditVitalQuestionAPI,
  getVitalDataAPI,
  getVitalParameterAPI,
  getVitalQuestionAPI,
  deleteVitalParamAPI,
} from "../../api/vitals";

const { dispatch } = store;

export function addEditVitals(payload) {
  // dispatch({
  // 	type: types.AUTH_LOGIN
  // })

  return addEditVitalAPI(payload);
}

export function getVitalData(data = {}) {
  return getVitalDataAPI(data);
}

export function addEditVitalQuestion(data = {}) {
  return addEditVitalQuestionAPI(data);
}
export function addEditVitalParam(data = {}) {
  return addEditVitalParamAPI(data);
}
export function getVitalParam(data = {}) {
  return getVitalParameterAPI(data);
}
export function getVitalQuestionApi(data = {}) {
  return getVitalQuestionAPI(data);
}
export function deleteVitalParam(payload) {
  return deleteVitalParamAPI(payload);
}
