import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

import SubMenu from "./SubMenu";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/reducers/common";
import dashboard from "../../routes/dashbaord";
import PerfectScrollbar from "perfect-scrollbar";

var ps;

const SideBar = (props) => {
  const sidebar = React.useRef();
  const isOpen = useSelector((state) => state.common.isSidebarOpen);
  const dispatch = useDispatch();
  const location = useLocation();
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebar.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });
  const onToggle = () => {
    dispatch(toggleSidebar());
  };

  console.log(location, "the location value");

  return (
    <div style={{ width: isOpen ? 300 : 0, transition: "0.5s" }}>
      <div
        style={
          isOpen
            ? {
                height: "100%",
                zIndex: 1000,
                position: "fixed",
                width: 250,
                top: 0,
                bottom: 0,
              }
            : { transition: "0.5s" }
        }
        className={classNames("sidebar", { "is-open": isOpen })}
      >
        <div className="sidebar-header">
          <span color="info" onClick={onToggle} style={{ color: "#fff" }}>
            &times;
          </span>
          <h3>Moringa</h3>
        </div>
        <div ref={sidebar} className="side-menu">
          <Nav vertical className="pb-3">
            <p> </p>
            {dashboard.map((route, key) => {
              const isAcitve = location.pathname?.includes(route.path || "");
              if (route.isHidden) {
                return <div key={key} />;
              }
              if (route.submenu) {
                return (
                  <SubMenu
                    title={route.name}
                    icon={route?.icon || faHome}
                    path={route.path}
                    items={route.submenu}
                    key={key}
                    activePath={location.pathname}
                    isAcitve={isAcitve}
                  />
                );
              }

              console.log(route.path, "the route apth");
              console.log(location.pathname, "the locaiton pathnaneoij");

              return (
                <NavItem
                  style={
                    isAcitve
                      ? {
                          marginLeft: 10,
                          backgroundColor: "#0e9b6d",
                          borderRadius: 10,
                          marginBottom: 10,
                        }
                      : {
                          marginLeft: 10,
                          backgroundColor: "transparent",
                          borderRadius: 10,
                          marginBottom: 10,
                        }
                  }
                  key={key}
                >
                  <NavLink tag={Link} to={route.path}>
                    <FontAwesomeIcon
                      icon={route.icon || faBriefcase}
                      className="mr-2"
                    />
                    {route.name}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </div>
      </div>
    </div>
  );
};

const submenus = [
  [
    {
      title: "Home 1",
      target: "Home-1",
    },
    {
      title: "Home 2",
      target: "Home-2",
    },
    {
      itle: "Home 3",
      target: "Home-3",
    },
  ],
  [
    {
      title: "Page 1",
      target: "Page-1",
    },
    {
      title: "Page 2",
      target: "Page-2",
    },
  ],
];

export default SideBar;
