import { apiPost, apiGet, apiReq, apiPut, randomString } from "../utils";
import qs from "query-string";
import { formTypes, authApiUrl, apiUrl } from "../constants";
import * as urls from "../config/urls";

const headers = {
//   "Content-Type": formTypes.urlencoded,
//   "X-Parse-Revocable-Session": 1,
//   "X-Parse-Application-Id": formTypes.appId,
//   'Access-Control-Allow-Origin' : '*',
//   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

export function addEditSymptomAPI(user) {
  const data = qs.stringify(user); // because api doesn't support application/json
  return apiPost(urls.ADD_EDIT_SYMPTOMS, user);
}

export function getSymptomDataAPI(data) {
  return apiPost(urls.LIST_SYMPTOMS,data);
}

export function deleteSymptomAPI(data) {
  return apiPost(urls.DELETE_BLOCK_SYMPTOMS, data);
}
