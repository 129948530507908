import { loginAPI } from "../../api/auth";
import types from "../types";
import store from "../store";
import { logout, saveUser } from "../../utils";
import { saveUserData } from "../reducers/auth";

const { dispatch } = store;

export function login(payload) {
  // dispatch({
  // 	type: types.AUTH_LOGIN
  // })

  return new Promise((response, rej) =>
    loginAPI(payload)
      .then((res) => {
        dispatch(saveUserData(res.data));

        saveUser(res.data);

        return response(res);
      })
      .catch((err) => {
        // dispatch({
        // 	type: types.AUTH_FAILED
        // })

        if (err)
          // showError(err)
          return rej(err);
      })
  );
}

export const logoutUser = () => {
  dispatch(saveUserData({}));
  return logout();
};
