import React from "react";
import ReactPaginate from "react-paginate";
export default function PaginationContainer({
  onPageClick,
  pageRangeDisplayed,
  pageCount,
}) {
  console.log(pageCount,'the page count valjojdf')
  return (
    <div className={"ml-auto"}>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next >"
        onPageChange={onPageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link page-change"
        previousClassName="page-item"
        previousLinkClassName="page-link page-change"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
