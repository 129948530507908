import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Label,
  FormText,
  FormFeedback,
  Col,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
import ClipLoader from "react-spinners/ClipLoader";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { toast } from "react-toastify";
import validateInput from "../../utilities/validations/Login";
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorObj, setErrorData] = useState({
    email: "",
    password: "",
  });

  const onChangeText = (e) => {
    if (e.target.name == "email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  const _onClick = () => {
    console.log("binonodsfo");
    const { errors, isValid } = validateInput({ email, password });

    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      setErrorData({});
    }

    setIsLoading(true);
    actions
      .login({
        email,
        password,
      })
      .then((res) => {
        setIsLoading(false);
        console.log(res, "the result value");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message || "Something went wrong", {
          toastId: "error",
        });
        console.log(error, "the error value");
      });
  };

  return (
    <div
      class="position-absolute"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(14, 155, 109, .3), rgba(14, 155, 109, .6)), url(/images/ad.jpg)`,
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        left: 0,
        height: "100%",
        width: "100%",
        right: 0,
        bottom: 0,
        top: 0,
        backgroundSize: "100% 100%",
        justifyContent:'center',
        display:'flex'
      }}
      
    >
      <Col className="mx-auto my-auto" lg="5" md="7">
        <Card className="bg-light shadow border-0">
          <CardHeader className="bg-transparent pb-5"></CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h5>Sign in</h5>
            </div>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email}
                  invalid={!!errorObj.email}
                  onChange={onChangeText}
                  autoComplete="new-email"
                />
                <FormFeedback>{errorObj.email}</FormFeedback>
                {/* <FormText>Example help text that remains unchanged.</FormText> */}
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  placeholder="Password"
                  value={password}
                  name={"password"}
                  onChange={onChangeText}
                  type="password"
                  invalid={!!errorObj.password}
                  autoComplete="new-password"
                />
                <FormFeedback>{errorObj.password}</FormFeedback>
                <FormFeedback>Oh noes! that name is already taken</FormFeedback>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                {/* <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label> */}
              </div>
              <div className="text-center">
                <Button
                  onClick={_onClick}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
      </Col>
      <FullScreenLoader isLoading={isLoading} />
    </div>
  );
};

export default Login;
