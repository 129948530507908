import { loginAPI } from "../../api/auth";
import types from "../types";
import store from "../store";
import {
  addEditDiseaseAPI,
  addEditDiseaseCategoryAPI,
  listDiseaseAPI,
  listDiseaseCategoryAPI,
  deleteDiseaseCategoryAPI,
  deleteDiseaseAPI
} from "../../api/disease";

const { dispatch } = store;

export function addEditDisease(payload) {
  return addEditDiseaseAPI(payload);
}

export function addEditDiseaseCategory(payload) {
  return addEditDiseaseCategoryAPI(payload);
}
export function listDisease(payload) {
  return listDiseaseAPI(payload);
}

export function listDiseaseCategory(payload) {
  return listDiseaseCategoryAPI(payload);
}

export function deleteDiseaseCategory(payload) {
  return deleteDiseaseCategoryAPI(payload);
}

export function deleteDisease(payload) {
  return deleteDiseaseAPI(payload);
}
