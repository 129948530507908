
import { apiPost, apiGet, apiReq, apiPut, randomString } from '../utils';
import qs from 'query-string';
import {formTypes, authApiUrl, apiUrl} from '../constants';
import * as urls from '../config/urls'


const headers = {
	'Content-Type': formTypes.urlencoded,
	'X-Parse-Revocable-Session' : 1,
	'X-Parse-Application-Id' : formTypes.appId,
    // 'Access-Control-Allow-Origin' : true,
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

export function loginAPI (user) {
	const data = qs.stringify(user); // because api doesn't support application/json
	return apiPost(urls.LOGIN ,user);
}