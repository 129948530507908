import { loginAPI } from "../../api/auth";
import types from "../types";
import store from "../store";
import {
  addEditProfileQuestionAPI,
  addEditOptionForQuestonAPI,
  lisiOptionsForQuestonAPI,
  lisiProfileQuestionsAPI,
} from "../../api/profileQuestions";

const { dispatch } = store;

export function addEditProfileQuestion(payload) {
  // dispatch({
  // 	type: types.AUTH_LOGIN
  // })

  return addEditProfileQuestionAPI(payload);
}

export function addEditOptionForQueston(data) {
  return addEditOptionForQuestonAPI(data);
}

export function lisiOptionsForQueston(data) {
  return lisiOptionsForQuestonAPI(data);
}

export function lisiProfileQuestions(data) {
  return lisiProfileQuestionsAPI(data);
}
