import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from "react-spinners/ClipLoader";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import ClockLoader from "react-spinners/ClockLoader";
import FadeLoader from "react-spinners/FadeLoader";
import GridLoader from "react-spinners/GridLoader";
import MoonLoader from "react-spinners/MoonLoader";
import PacmanLoader from "react-spinners/PacmanLoader";
import RiseLoader from "react-spinners/RiseLoader";
import RotateLoader from "react-spinners/RotateLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default function FullScreenLoader({ isLoading }) {
  if (!isLoading) {
    return <div />;
  }
  return (
    <Modal contentClassName="loader" centered isOpen={isLoading}>
      
    <div
      style={{
     
        justifyContent: "center",
        display: "flex",
        
        alignItems: "center",
        zIndex: 400,
      }}
    >
     
      {/* 
      <
      
      <FadeLoader/> */}
      {/* <ClockLoader /> */}
      {/* <RotateLoader color={'rgba(14, 155, 109, 1)'}    loading size={20} /> */}
      {/* <ClipLoader
        // color={"rgba(14, 155, 109, 1)"}
        cssOverride={{}}
        loading={isLoading}
        size={120}
      /> */}
      <RotateLoader />
      {/* <MoonLoader   size={130}  /> */}

      {/* <ClimbingBoxLoader  color={'rgba(14, 155, 109, 1)'} size={30} /> */}
      {/* <GridLoader  color={'rgba(14, 155, 109, 1)'} size={30} /> */}
      {/* <MoonLoader color={'rgba(14, 155, 109, 1)'}  size={130}  /> */}
      {/* <PacmanLoader /> */}
      {/* <RiseLoader  color={'rgba(14, 155, 109, 1)'} size={20} /> */}
      {/* <RotateLoader />
      <ScaleLoader /> */}
    </div>
    </Modal>
  );
}
