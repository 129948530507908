import Questions from "../views/Questions/Questions";
import CreateVital from "../views/Vitals/CreateVital";
import Vital from "../views/Vitals/Vitals";
import * as Views from "../views";
import {
  faClipboardQuestion,
  faPersonCircleQuestion,
  faPersonRays,
  faFileMedical,
  faDashboard,
  faDisease,
  faTemperatureHalf,
  faCircleNodes,
} from "@fortawesome/free-solid-svg-icons";

const dashboard = [
  {
    Element: Views.Overview,
    path: "/overview",
    name: "OverView",
    icon: faDashboard,
  },
  ,
  {
    Element: Vital,
    path: "/vital",
    name: "Vitals",
    icon: faTemperatureHalf,
    submenu: [
      {
        path: "",
        Element: Views.VitalParameters,
        name: "Parameter",
      },
      {
        path: "/vital_range",
        Element: Vital,
        name: "Range",
      },
      {
        path: "/vital_question",
        Element: Views.VitalQuestion,
        name: "Questions",
      },
    ],
  },
  {
    Element: CreateVital,
    path: "/vital/create",
    name: "Vitals",
    isHidden: true,
  },
  {
    Element: Questions,
    path: "/question",
    name: "Questions",
    icon: faPersonCircleQuestion,
    submenu: [
      {
        path: "/physical",
        Element: Questions,
        name: "Physical",
      },
      {
        path: "/social",
        Element: Questions,
        name: "Social",
      },
      {
        path: "/surgical",
        Element: Questions,
        name: "Surgical",
      },
      {
        path: "/familyMember",
        Element: Questions,
        name: "Member History",
      },
      {
        path: "/tabacoo",
        Element: Questions,
        name: "Tabacoo",
      },
      {
        path: "/alcohal",
        Element: Questions,
        name: "Alcohal",
      },
      {
        path: "/physicalB",
        Element: Questions,
        name: "Physical B",
      },
    ],
  },
  {
    Element: Views.Symptoms,
    path: "/symptoms",
    name: "Symptoms",
    icon: faPersonRays,
  },
  {
    Element: Views.CreateEditSymptoms,
    path: "/symptoms/create",
    name: "Symptoms",
    isHidden: true,
  },
  {
    Element: Views.DiseaseCategory,
    path: "/disease_category",
    name: "Disease Category",
    icon: faFileMedical,
  },
  {
    Element: Views.Disease,
    path: "/diseases",
    name: "Disease",
    icon: faDisease,
  },
  {
    Element: Views.CreateEditQuestion,
    path: "/question/create",
    name: "Question",
    isHidden: true,
  },
  {
    Element: Views.Options,
    path: "/question/options",
    name: "Question",
    isHidden: true,
  },
  {
    Element: Views.Relations,
    path: "/relation",
    name: "Relations",
    icon: faCircleNodes,
  },
];

export default dashboard;
