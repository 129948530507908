import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Switch, Route, Routes, Outlet } from "react-router-dom";

import Topbar from "./Topbar";
import { toggleSidebar } from "../../redux/reducers/common";
import { useDispatch, useSelector } from "react-redux";
import Login from "../../views/Login/Login";

const Content = ({}) => {
  const sidebarIsOpen = useSelector((state) => state.common.isSidebarOpen);
  const dispatch = useDispatch();

  const onToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Container
      fluid
      className={classNames("content", { "is-open": sidebarIsOpen })}
    >
      <Topbar toggleSidebar={onToggle} />
       <Outlet />
    </Container>
  );
};

export default Content;
