import * as auth from "./auth";
import * as vitals from "./vitals";
import * as symptoms from "./symptoms";
import * as profileQustions from "./profileQustions";
import * as disease from "./disease";
import * as common from './common'

export default {
  ...auth,
  ...vitals,
  ...symptoms,
  ...profileQustions,
  ...disease,
  ...common
};
