import React from "react";
import { useSelector } from "react-redux";

import { Navigate, Outlet } from "react-router-dom";
import actions from "../redux/actions";

const PublicRoutes = () => {
  const userData = useSelector((state) => state.auth.userData);
  const isLogin = !!userData.accessToken;
  

  return !isLogin ? <Outlet /> : <Navigate to="/" />;
};

export default PublicRoutes;
