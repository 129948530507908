import React from "react";
import Login from "../../views/Login/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import dashboard from "../../routes/dashbaord";
export default function DashboardRoutes() {
  return (
    <React.Fragment>
      <Route path="/" element={<Navigate replace to="overview" />} />
      {dashboard.map((route, key) => {
        if (!!route.submenu) {
          return route.submenu.map((subroute, key) => {
            return (
              <Route
                path={`${route.path}${subroute.path}`}
                key={key}
                element={<subroute.Element />}
              />
            );
          });
        }

        return (
          <Route path={route.path} key={key} element={<route.Element />} />
        );
      })}
    </React.Fragment>
  );
}
