import { apiPost, apiGet, apiReq, apiPut, randomString } from "../utils";
import qs from "query-string";
import { formTypes, authApiUrl, apiUrl } from "../constants";
import * as urls from "../config/urls";

const headers = {
  //   "Content-Type": formTypes.urlencoded,
  //   "X-Parse-Revocable-Session": 1,
  //   "X-Parse-Application-Id": formTypes.appId,
  //   'Access-Control-Allow-Origin' : '*',
  //   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

export function addEditVitalAPI(user) {
  const data = qs.stringify(user); // because api doesn't support application/json
  return apiPost(urls.ADD_EDIT_VITAL, user);
}

export function addEditVitalQuestionAPI(data = {}) {
  return apiPost(urls.ADD_VITAL_QUESTION, data);
}

export function addEditVitalParamAPI(data = {}) {
  return apiPost(urls.ADD_EDIT_VITAL_PARAM, data);
}

export function getVitalDataAPI(data = {}) {
  return apiPost(urls.LIST_VITAL_DATA, data);
}

export function getVitalParameterAPI(data = {}) {
  return apiPost(urls.LIST_VITAL_PARAMETER, data);
}

export function getVitalQuestionAPI(data = {}) {
  return apiPost(urls.LIST_VITAL_QUESTION, data);
}
export function deleteVitalParamAPI(data = {}) {
  return apiPost(urls.DELETE_BLOCK_VITAL_PARAM, data);
}
