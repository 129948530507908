import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ConfirmationAlertModal({
  isOpen,
  onClose,
  onConfrim,
  title,
  body,
  confrimText,
}) {
  return (
    <Modal centered isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfrim}>
          {confrimText}
        </Button>{" "}
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationAlertModal;
