import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import ConfirmationAlertModal from "../ConfirmationAlertModal";
import actions from "../../redux/actions";

const Topbar = ({ toggleSidebar }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const [openLogoutAlert, setLogoutAlert] = React.useState(false);

  const onLogoutUser = () => {
    actions.logoutUser();
  };
  return (
    <Navbar
      color="light"
      light
      className="navbar shadow-sm p-3 mb-5 bg-white rounded"
      expand="md"
    >
      <Button color="info" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>
      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} navbar>
        <Nav style={{cursor:'pointer'}} onClick={() => setLogoutAlert(true)} className="ml-auto" navbar>
          <NavItem>
            <NavLink>Sign out</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
      <ConfirmationAlertModal
        title="Sign out"
        body="Are you sure you want to signout?"
        onClose={() => setLogoutAlert(false)}
        confrimText="Yes"
        onConfrim={onLogoutUser}
        isOpen={openLogoutAlert}
      />
    </Navbar>
  );
};

export default Topbar;
