//File Name: Login.js
//Path: src/utilities/validations/
//Description: This file contains validation functions for student login form.
import Validator from "is_js";
import { isEmpty } from "lodash";

export default function validateInput(data) {
  let errors = {};

  Object.keys(data).forEach((val) => {
    let valueData = data[val]
    let dataValue = String(valueData.value||"").trim();
    console.log(valueData)

    if (Validator.empty(dataValue||"")) {
      console.log(val.name);
      errors[val] = `${valueData.name} is required`;
    }
  });

  console.log(errors,'the error data')
  return {
    errors,
    isValid: isEmpty(errors),
  };
}
