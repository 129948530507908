import React, { useState } from "react";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import { Card, Container, Nav, Navbar, Table } from "reactstrap";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";
import CreateRelations from "./CreateRelations";

export default function DiseaseCategory(props) {
  const location = useLocation();
  const [tableData, setTableData] = React.useState([]);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [state, setState] = React.useState({
    optionString: "",
    value: "",
    sequenceNo: "",
  });
  const [editData, setEditData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);
  const [vitalParamData, setVitalParamData] = React.useState([]);
  const [symptomsData, setSymptomsData] = React.useState([]);
  const [diseaseData, setDiseaseData] = React.useState([]);
  React.useEffect(() => {
    getApiCall();
  }, []);

  React.useEffect(() => {
    actions
      .getVitalParam({
        pageNo: 1,
      })
      .then((res) => {
        console.log(res, "the result value");
        const vitalData = res.data?.listRecord.map((val) => ({
          value: val.id,
          label: val?.name || "",
        }));
        setVitalParamData(vitalData);
      });

    actions.getSymptoms({pageNo:1}).then((res) => {
      console.log(res, "bogy");
      const symptomsData = res.data.rows.map((val) => {
        return {
          value: val.id,
          label: val.symptomsName,
        };
      });
      setSymptomsData(symptomsData);
    });

    actions
      .listDisease({
        pageNo: 1,
      })
      .then((res) => {
        const diseaseData = res.data.rows.map((val) => {
          return {
            value: val.id,
            label: val.name,
          };
        });
        setDiseaseData(diseaseData);
      });
  }, []);

  const getApiCall = () => {
    actions.listRelationsData({ pageNo: 1 }).then((res) => {
      console.log(res, "the bing");

      const tablDataToSet = res.data?.listRecord.map((val) => {
        return {
          name: val.groupName,
          ["searchTags"]: val.searchTags,
          ["comments"]: val.comments,
          symptom: val[`symptomsIdVSD.symptomsName`] || "",
          symptomsId: val.symptomsId,
          vitalParamValue: val[`vitalParametereIdVSD.name`],
          vitalParametereId: val.vitalParametereId,
          vitalRangeValue:
            (val[`vitalRangeIdVSD.vitalStartRange`] &&
              `${val[`vitalRangeIdVSD.vitalEndRange`]} - ${
                val[`vitalRangeIdVSD.vitalStartRange`]
              }`) ||
            "-",
          vitalRangeId: val.vitalRangeId,
          disease: val[`diseaseIdVSD.name`] || "-",
          diseaseId: val.diseaseId,
          id: val.id,
          status:val.status
        };
      });
      setTableData(tablDataToSet);
    });
  };

  const _onDeleteConfirm = (data,isBlockAction) => {
    let status="DELETE";
    if(isBlockAction){
      status=data.status=="BLOCK"?"UNBLOCK":"BLOCK"
    }
    actions
      .deleteBlockUnblockRelations({
        status,
        id: data.id,
      })
      .then((res) => {
        getApiCall();
      })
      .catch((error) => {
        showError(error);
      });
  };

  const data = React.useMemo(() => tableData, [tableData]);
  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Tags",
        accessor: "searchTags",
      },
      {
        Header: "Vit.Param",
        accessor: "vitalParamValue",
      },
      {
        Header: "Vit. Range",
        accessor: "vitalRangeValue",
      },
      {
        Header: "Symptoms",
        accessor: "symptom",
      },
      {
        Header: "Disease",
        accessor: "disease",
      },
      {
        Header: "Comment",
        accessor: "comments",
      },
    ],
    []
  );

  const toggleModal = () => {
    setisEdit(false);
    setIsModalVisible(false);
  };

  const openModalReleation = () => {
    setisEdit(false);
    setIsModalVisible(true);
    setEditData({});
  };

  const onOptionSubmit = () => {
    const { isValid, errors } = validateInput({
      optionString: { value: state.optionString, name: "Question" },
      sequenceNo: { value: state.sequenceNo, name: "Sequence no" },
      value: { value: state.value, name: "Score Deduction" },
    });

    console.log(errors, "the errors data value");
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      console.log(location, "the locaitojo");
      const { data } = location?.state;
      const questionId = data.id;
      actions
        .addEditOptionForQueston({
          ...state,
          questionId: questionId + "",
        })
        .then((res) => {
          getApiCall();
          console.log(res, "the resule vaue");
          setIsLoading(false);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.log(error, "the error value");
          setIsLoading(false);
          showError(error);
        });
    }
  };

  const onAddEditomplete = () => {
    setIsModalVisible(false);
    getApiCall();
  };

  const _onEdit = (data) => {
    let editData = {
      name: data.name,
      comments: data.comments,
      searchTags: data.searchTags,
      id: data.id,
    };

    if (data.symptomsId) {
      editData.selectedSymptom = {
        value: data.symptomsId,
        label: data.symptom,
      };
    }
    if (data.vitalParametereId) {
      editData.selectedVitalParam = {
        value: data.vitalParametereId,
        label: data.vitalParamValue,
      };
    }
    if (data.vitalRangeId) {
      editData.selectedVitalRange = {
        value: data.vitalRangeId,
        label: data.vitalRangeValue,
      };
    }
    if (data.diseaseId) {
      editData.selectedDisease = {
        value: data.diseaseId,
        label: data.disease,
      };
    }

    setEditData(editData);
    setIsModalVisible(true);
    setisEdit(true);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>

        <div
          className="create-btn"
          style={{ textDecoration: "none" }}
          onClick={openModalReleation}
        >
          Add New Relation
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={`${row.original.status=="BLOCK"?'block':""}`} {...row.getRowProps()}>
                  <td className="texta">{i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      onEdit={_onEdit}
                      data={row.original || {}}
                      onDeleteConfirm={_onDeleteConfirm}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      {modalVisible && (
        <CreateRelations
          modalVisible={modalVisible}
          onAddEditomplete={onAddEditomplete}
          toggleModal={toggleModal}
          isEdit={isEdit}
          editData={editData}
          vitalParamData={vitalParamData}
          symptomsData={symptomsData}
          diseaseData={diseaseData}
        />
      )}
    </Container>
  );
}
