import { loginAPI } from "../../api/auth";
import types from "../types";
import store from "../store";
import { addEditRelationAPI, listRelationsDataAPI,deleteBlockUnBlockAPI,deleteBlokcRelationAPI } from "../../api/common";

const { dispatch } = store;

export function addEditRelation(payload) {
  return addEditRelationAPI(payload);
}

export function listRelationsData(payload) {
  return listRelationsDataAPI(payload);
}

export function deleteBlockUnblock(payload) {
  return deleteBlockUnBlockAPI(payload);
}

export function deleteBlockUnblockRelations(payload) {
  return deleteBlokcRelationAPI(payload);
}
