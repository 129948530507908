import { apiPost, apiGet, apiReq, apiPut, randomString } from "../utils";
import qs from "query-string";
import { formTypes, authApiUrl, apiUrl } from "../constants";
import * as urls from "../config/urls";

const headers = {
  //   "Content-Type": formTypes.urlencoded,
  //   "X-Parse-Revocable-Session": 1,
  //   "X-Parse-Application-Id": formTypes.appId,
  //   'Access-Control-Allow-Origin' : '*',
  //   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

export function addEditRelationAPI(data) {
  return apiPost(urls.CREATE_EDIT_RELATION, data);
}

export function listRelationsDataAPI(data) {
  return apiPost(urls.LIST_RELATION, data);
}

export function deleteBlockUnBlockAPI(data) {
  return apiPost(urls.DELETE_BLOCK_UNBLOCK, data);
}

export function deleteBlokcRelationAPI(data = {}) {
  return apiPost(urls.DELETE_BLOCK_RELATION, data);
}