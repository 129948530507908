import { toast } from "react-toastify";

export const showError = (error) => {
  toast.error(error?.message || "Something went wrong", {
    toastId: "error",
  });
};

export const showSuccess = (message) => {
  toast.success(message, {
    toastId: "success",
  });
};
