import {createSlice} from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isSidebarOpen:true
  },
  reducers: {
    toggleSidebar: (state, action) => {
      state.isSidebarOpen=!state.isSidebarOpen
    },
  },
});

export const {toggleSidebar} = commonSlice.actions;

export default commonSlice.reducer;
