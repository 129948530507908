import React, { useState } from "react";
import { Navigate, useHistory, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  FormFeedback,
} from "reactstrap";
import actions from "../../redux/actions";
import validateInput from "../../utilities/validations/emptyValidator";
import { useLocation } from "react-router-dom";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { showError } from "../../utilities/alerts/toastAlerts";

const getStep = (type) => {
  type = parseInt(type);
  switch (type) {
    case 1:
      return "STEP_1";
    case 2:
      return "STEP_1";
    case 3:
      return "STEP_2";
    case 4:
      return "STEP_3";
    case 5:
      return "STEP_4";
    case 6:
      return "STEP_5";
    case 7:
      return "STEP_6";
  }
};

export default function CreateVital(props) {
  console.log(props, "the props value");
  let location = useLocation();
  const navigate = useNavigate();
  console.log(location, "the creawte vital locaoijoj");
  const { isEdit = false, data = {}, type, parentName } = location?.state || {};
  console.log(location.state, "the location state value");
  const [state, setState] = React.useState({
    question: data.question || "",

    sequenceNo: data.sequenceNo || "",
    isTextInput:
      (data.questionType && (data.questionType == 1 ? true : false)) || true,
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const [errorObj, setErrorData] = useState({
    question: "",
    questionType: "",
    sequenceNo: "",
  });

  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onCreateQuestion = () => {
    console.log("qyhh quesiton");
    console.log(state, "the state data value");
    const { isValid, errors } = validateInput({
      question: { value: String(state.question || ""), name: "Question" },
      sequenceNo: {
        value: String(state.sequenceNo || ""),
        name: "Sequence no",
      },
    });

    console.log(errors, "the errors data value");
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      setIsLoading(true);
      const { isTextInput, ...stateApiData } = state;
      let apiData = {
        ...stateApiData,
        type,
        questionType: isTextInput ? 1 : 2,
        step: getStep(type),
      };
      console.log(apiData, "the api data value is as follow");
      if (isEdit) {
        apiData.id = data.id;
      }
      actions
        .addEditProfileQuestion(apiData)
        .then((res) => {
          setIsLoading(false);
          navigate(-1);
          console.log(res, "the result value");
        })
        .catch((error) => {
          console.log(error, "the error value");
          setIsLoading(false);
          showError(error);
        });
    }
  };

  const changeType = (e) => {};

  console.log(isEdit, "the is edi");
  return (
    <Container fluid>
      <Col md="5" className="mb-3">
        <Card className="shadow p-3 pt-4 mb-5 bg-white ">
          <CardHeader className="chead">
            <CardTitle className="question-heading" tag="h5">
              {isEdit ? `Edit` : `Create`} {parentName} Question
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Form>
              {/* <FormGroup>
                <label>Vital Id</label>
                <Input
                  name={"vitalId"}
                  onChange={onChangeText}
                  value={state.vitalId}
                  invalid={!!errorObj.vitalId}
                  type="text"
                />
                <FormFeedback>{errorObj.vitalId}</FormFeedback>
              </FormGroup> */}

              <FormGroup>
                <label>Question</label>
                <Input
                  name={"question"}
                  onChange={onChangeText}
                  value={state.question}
                  invalid={!!errorObj.question}
                  type="text"
                />
                <FormFeedback>{errorObj.question}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <label>Question Type</label>
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="textInput"
                    id="flexRadioDefault1"
                    checked={state.isTextInput}
                    onClick={() => updateState({ isTextInput: true })}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    Text Input
                  </label>
                </div>
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={!state.isTextInput}
                    onClick={() => updateState({ isTextInput: false })}
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Dropdown
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <label>Sequence No</label>
                <Input
                  name={"sequenceNo"}
                  onChange={onChangeText}
                  value={state.sequenceNo}
                  invalid={!!errorObj.sequenceNo}
                  type="number"
                />
                <FormFeedback>{errorObj.sequenceNo}</FormFeedback>
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter className="cfoot">
            <div className="update ml-auto mr-auto">
              <Button
                onClick={onCreateQuestion}
                className="btn-round new-prime"
                color="primary"
                type="submit"
              >
                {isEdit ? "Update" : `Create`}
              </Button>
            </div>
          </CardFooter>
        </Card>
        <div style={{ height: 30 }} />
      </Col>
      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
}
