import { apiPost, apiGet, apiReq, apiPut, randomString } from "../utils";
import qs from "query-string";
import { formTypes, authApiUrl, apiUrl } from "../constants";
import * as urls from "../config/urls";

const headers = {
//   "Content-Type": formTypes.urlencoded,
//   "X-Parse-Revocable-Session": 1,
//   "X-Parse-Application-Id": formTypes.appId,
//   'Access-Control-Allow-Origin' : '*',
//   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

export function addEditProfileQuestionAPI(data) {
  
  return apiPost(urls.ADD_EDIT_PROFILE_QUESTIONS, data);
}

export function addEditOptionForQuestonAPI(data) {
  return apiPost(urls.ADD_EDIT_OPTION_FOR_QUESTIONS,data);
}

export function lisiOptionsForQuestonAPI(data) {
  return apiPost(urls.LIST_OPTION_FOR_QUESTIONS,data);
}

export function lisiProfileQuestionsAPI(data) {
  return apiPost(urls.LIST_PROFILE_QUESTION_DATA,data);
}
