import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const { icon, title, items, isAcitve, activePath } = props;

  return (
    <div
      style={{
        marginBottom: 10,
      }}
    >
      <NavItem
        onClick={toggle}
        style={{
          marginLeft: 10,
          backgroundColor: "transparent",
          borderRadius: 10,
        }}
      >
        <NavLink>
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {title}
        </NavLink>
      </NavItem>
      <Collapse isOpen={!collapsed}>
        {items.map((item, index) => {
          const myCompletePath = `${props.path}${item.path}`;
          const isMeActive = activePath === myCompletePath;
          return (
            <NavItem
              style={
                isMeActive
                  ? {
                      marginLeft: 10,
                      backgroundColor: "#0e9b6d",
                      borderRadius: 10,
                    }
                  : {
                      marginLeft: 10,
                      backgroundColor: "transparent",
                      borderRadius: 10,
                    }
              }
              key={index}
              className="pl-4"
            >
              <NavLink tag={Link} to={`${props.path}${item.path}`}>
                {item.name}
              </NavLink>
            </NavItem>
          );
        })}
      </Collapse>
    </div>
  );
};

export default SubMenu;
