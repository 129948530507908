import React from "react";
import Content from "../../components/content/Content";
import SideBar from "../../components/sidebar/SideBar";

export default function DashboardContainer() {
  return (
    <div className="App wrapper">
      <SideBar />
      <Content />
    </div>
  );
}
